import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./routes/router";
import Loader from "./components/loader";
import ErrorFeedback from "./components/errorfeedback";



function App() {

  return (
    <>
      <Suspense
        fallback={
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh' // Full viewport height
          }}>
            <Loader />
          </div>
        }
      >
        <RouterProvider router={routes} />
      </Suspense>
      
      {/* Global Error Feedback Modal */}
      <ErrorFeedback />
    </>
  );
}

export default App;
